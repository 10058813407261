<template>
  <div>
      <el-container>
          <!-- <img src="@/assets/tou.jpg" alt="" class="tou"> -->
        <el-header
          class="navBox"
        >
        <!-- :class="
            routeData1.indexOf($route.name) === -1 ? 'greenNav' : ''
          " -->
          <!-- 菜单组件 -->
          <menu-tab />
        </el-header>
        <!-- 内容部分 -->
        <el-main
          id="main1"
          class="main"
        >
          <!-- 展示路由 -->
          <router-view
          ></router-view>
          <el-backtop target=".main"></el-backtop>
          <el-footer  class="footer">
            <el-row class="footerRow">
              <el-col :span="2">
                <!-- <el-col :span="12" style="text-align: right;"> -->
                  <img src="@/assets/logofooter.png" alt="" class="footerPic">
                <!-- </el-col> -->
                <!-- <el-col :span="12" class="footerText"> -->
                <!-- </el-col> -->
              </el-col>
              <el-col :span="6" class="footerText">
                  <div>Telephone：022-29503270</div>
                  <div>Email：support@smartgenomics.cn</div>
              </el-col>
              <el-col :span="10" class="footerText">
                <div>Website：www.smartgenomics.net</div>
                <div style="display:flex;">Address：<div>Floor 2, Building A9, International Enterprise Community, Wuqing Business District, Tianjin</div></div>
              </el-col>
              <el-col :span="6"  class="footerRightText">
                <img src="@/assets/ma.png" alt="" style="width: 20%;margin-right: 3%;">
                <div>
                  <div style="letter-spacing: 0;">ABOUT US 关于极智</div>
                  <div><a href="https://smartgenomics.net/" style="color: #324d8d;text-decoration: none;" target="_blank">天津极智基因科技有限公司</a></div>
                </div>
              </el-col>
            </el-row>
          </el-footer>
        </el-main>
        
      </el-container>
      
  </div>
</template>

<script>
import MenuTab from "@/components/MenuTab/index.vue";
export default {
  name: 'home',
   props:{
   //传值
   },
  data() {
    return {
        // routeData: ["login"],
        // routeData1: ["首页"],
      isRouterAlive: true,
      bol:false,

    };
  },
  components:{
    //引入模块
    MenuTab,
    // leftMenu
  },
  methods: {
    // 组件的方法
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
    
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  beforeCreate: function() {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function() {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function() {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  mounted: function() {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log('Home');
    /* this.$watch(
      () => {
       return this.$refs.leftMenuref?this.$refs.leftMenuref.isCollapse:true
      },
      (val) => {
         this.bol=this.$refs.leftMenuref.isCollapse
      }
    ) */
  },
  beforeUpdate: function() {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function() {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function() {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function() {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  }
};

</script>
<style scoped>
.el-container{
    height: 100vh;
    /* font-size:14px; */
}
.tou{
  height: 100px;
}
.innerMain{
  /* background: #eee; */
  padding: 50px;
}
.mainHeight {
  overflow: hidden;
}
.overHeight {
  /* height: calc(120vh - 0rem); */
  overflow-x: hidden !important;
  overflow-y: auto;
}
.pading {

}
.overPading {
  padding: 0 !important;
}
.navBox {
  background: #b5c0cb;
  
  height: 60px !important;
  line-height: 60px;
  /* height: 200px;
  height: calc(100vh - 80px) !important; */
}
.greenNav{
  background: #409eff;
}

.topNav {
  background: linear-gradient(
    270deg,
    rgba(72, 109, 139, 1) 0%,
    rgba(123, 194, 235, 1) 100%
  );
  height: 80px !important;
  padding: 0 50px 0 10px;
}
.footBox {
  margin-top: 30px;
}
.main{
  padding: 0;
  overflow-x: hidden;
}
.imgShou{
  width: 100px !important;/*no*/
}
.footer{
  text-align: left;
  height: 154px !important;
  padding: 0px 200px;
      background-color: #b5c0cb;
  /* background-image: url("@/assets/di.png"); */
    color: black;
}
.footer .footerRow{
  padding-top: 10px;
    /* padding-bottom: 20px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
        line-height: 2em;
    font-weight: bold;
}
.footer .footerPic{
  width: 60%;
    margin-right: 10px;
}
/* .footer .footerText div{
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
} */
.footer .footerText{
  border-left: 4px solid #083090;
  padding-left: 10px;
  font-weight: normal;
}
.footer .footerRightText{
    display: flex;
    align-items: center;
    background: white;
    justify-content: center;
    height: 90px;
}
.footer .footerRightText div{
  /* margin-bottom: 10px; */
  font-weight: bold;
  /* letter-spacing: 2px; */
}
.footer .footerRightText div:nth-child(1){
  font-size: 20px;
  color: #324d8d;
}
</style>
<style>

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app,
.box {
  height: 100%;
}
.el-container {
  height: 100%;
}

a:hover{
  cursor: pointer;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 3px;
  box-shadow: inset 0 0 5px #409eff;
  background: #409eff;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px #409eff;
  border-radius: 3px;
  background: #ededed;
}
.el-table--medium .el-table__cell{
  padding: 0px !important;
}
/* .greenBu{
  background: #409eff !important;
  border-color: #409eff !important;
  color: white !important;
}
.greenEg{
  color: #409eff !important;
} */
/* .mainBox {
  background: white;
    padding-top: 20px;
    margin: 0 auto;
    min-height: calc(100vh - 120px);
} */
/* .titleBox{
  font-size: 20px !important;
  font-weight: bold;
} */

</style>
<style>
.el-pagination .el-select .el-input{
  width: 140px !important;
}
.el-radio__input.is-checked .el-radio__inner{
  background: #409eff !important;
  border-color: #409eff !important;
}
.el-radio__input.is-checked+.el-radio__label{
  color: #409eff !important;
  border-color: #409eff !important;
}
</style>