
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout 竖向*/
// import Layout from '@/views/layout/Layout'
/* abeam 横向*/
import Layout from '@/Layout'


export const routerMap = {
  Layout: () => Layout,

}
export const constantRouterMap = [
  //首次加载时先走登录页
  {
    path: '/login',
    name: 'login',
    component: () => {
      return import("@/views/login/index")
    }
  },

  //路由重定向
  {
    path: '/',
    redirect: "/login"
  },
  {
    path: '/',
    redirect: "/home"
  },
  // 没有下拉菜单，页面卸载children中
  {
    path: '/homeView',
    component: Layout,
    redirect: 'home',
    name: 'homeView',
    alwaysShow: true,
    meta: {
      title: 'Home',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/home',
        component: () => import('@/views/home'),
        name: 'home',
        meta: { title: 'Home',title1: '首页', }
      },
    ]
  },
  {
    path: '/AtlasView',
    component: Layout,
    redirect: 'Atlas',
    name: 'AtlasView',
    alwaysShow: true,
    meta: {
      title: 'Atlas',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/Atlas',
        component: () => import('@/views/Atlas'),
        name: 'Atlas',
        meta: { title: 'Atlas',title1: '首页', }
      },
    ]
  },
  /* {
    path: '/SearchView',
    component: Layout,
    redirect: 'Search',
    name: 'SearchView',
    alwaysShow: true,
    meta: {
      title: 'Search',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/SingleCellRNA',
        component: () => import('@/views/search/SingleCellRNA'),
        name: 'SingleCellRNA',
        meta: { title: 'Single Cell RNA',title1: '首页', }
      },
      {
        path: '/SingleCellATAC',
        component: () => import('@/views/search/SingleCellATAC'),
        name: 'SingleCellATAC',
        meta: { title: 'Single Cell ATAC',title1: '首页', }
      },
    ]
  }, */
  {
    path: '/EnrichmentView',
    component: Layout,
    redirect: 'Enrichment',
    name: 'EnrichmentView',
    alwaysShow: true,
    meta: {
      title: 'Enrichment',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/GO',
        component: () => import('@/views/Enrichment/GO'),
        name: 'GO',
        meta: { title: 'GO',title1: '首页', }
      },
      {
        path: '/KEGG',
        component: () => import('@/views/Enrichment/KEGG'),
        name: 'KEGG',
        meta: { title: 'KEGG',title1: '首页', }
      },
      {
        path: '/Reactome',
        component: () => import('@/views/Enrichment/Reactome'),
        name: 'Reactome',
        meta: { title: 'Reactome',title1: '首页', }
      },
    ]
  },
  {
    path: '/GeneView',
    component: Layout,
    redirect: 'Gene',
    name: 'GeneView',
    alwaysShow: true,
    meta: {
      title: 'Gene',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/Gene',
        component: () => import('@/views/Gene'),
        name: 'Gene',
        meta: { title: 'Gene',title1: '首页', }
      },
    ]
  },
  {
    path: '/CellTypeView',
    component: Layout,
    redirect: 'CellType',
    name: 'CellTypeView',
    alwaysShow: true,
    meta: {
      title: 'Cell Type',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/CellType',
        component: () => import('@/views/CellType'),
        name: 'Cell Type',
        meta: { title: 'CellType',title1: '首页', }
      },
    ]
  },
  {
    path: '/NetworkView',
    component: Layout,
    redirect: 'Network',
    name: 'NetworkView',
    alwaysShow: true,
    meta: {
      title: 'Network',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/Network',
        component: () => import('@/views/Network'),
        name: 'Network',
        meta: { title: 'Network',title1: '首页', }
      },
    ]
  },
  // {
  //   path: '/ToolsView',
  //   component: Layout,
  //   redirect: 'Tools',
  //   name: 'ToolsView',
  //   alwaysShow: true,
  //   meta: {
  //     title: 'Tools',//标题
  //     title1: '首页',
  //     icon: 'Tools'//图标
  //   },
  //   children: [
  //     {
  //       path: '/Tools',
  //       // component: () => import('@/views/Tools'),
  //       name: 'Tools',
  //       meta: { title: 'Tools',title1: '首页', }
  //     },
  //   ]
  // },
  
  {
    path: '/DownloadView',
    component: Layout,
    redirect: 'Download',
    name: 'DownloadView',
    alwaysShow: true,
    meta: {
      title: 'Download',//标题
      title1: '首页',
      icon: 'Download'//图标
    },
    children: [
      {
        path: '/Download',
        component: () => import('@/views/Download'),
        name: 'Download',
        meta: { title: 'Download',title1: '首页', }
      },
    ]
  },
  
  {
    path: '/StatisticsView',
    component: Layout,
    redirect: 'Statistics',
    name: 'StatisticsView',
    alwaysShow: true,
    meta: {
      title: 'Statistics',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/Statistics',
        component: () => import('@/views/Statistics'),
        name: 'Statistics',
        meta: { title: 'Statistics',title1: '首页', }
      },
    ]
  },
  /* {
    path: '/browseView',
    component: Layout,
    redirect: 'Function',
    name: 'browseView',
    alwaysShow: true,
    meta: {
      title: 'Browse',//标题
      title1: '查询',
      icon: 'search'//图标
    },
    children: [
      {
        path: '/Function',
        component: () => import('@/views/pages/search'),
        name: 'Function',
        meta: { title: 'Functional Annotation', title1: '功能注释',icon:'chazhao'}
      },{
        path: '/pathway',
        component: () => import('@/views/pages/pathway'),
        name: 'pathway',
        meta: { title: 'Pathway Infomation', title1: '通路信息',icon: 'pathway'}
      },{
        path: '/Transposable-Elements',
        component: () => import('@/views/pages/TE'),
        name: 'Transposable Elements',
        meta: { title: 'Transposable Elements',title1: '转位因子', icon: 'te'}
      }
    ]
  }, */
  
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
]

