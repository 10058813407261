<template>
  <div>
    <el-row style="display: flex;
    width: 92%;justify-content: space-around;">
      <el-col :span="10"><img src="@/assets/dataLogo.png" alt="" class="pic"></el-col>
      <el-col :span="14" style="position:relative;">
        <!-- <img src="@/assets/menu.jpg" alt=""> -->
              <!-- <svg-icon :data_iconName="$route.path=='/'?'logoBlack':'logoWhite'" :className="$route.path=='/'?'logoBlack_icon':'logoWhite_icon'" class="svgIcon svg"
            /> -->
        
        <el-menu
          :default-active="navLeftActive"
          class="el-menu-demo TopMenu menu"
          background-color="rgb(0 0 0 / 0%)"
          mode="horizontal"
          active-background-color="#007635"
          :router="true"
          :collapse-transition="false"
          @select="handleSelect"
        >
          <template v-for="(item, index) in routerList">
            <el-submenu
              :index="item.path"
              :key="index"
              v-if="item.children.length != 1"
              class="submenu"
            >
              <template slot="title">
                <!-- <i class="marginRight svgI" :style="{color:color}">
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i> -->
                <span slot="title" style="color:black" >{{ item.meta.title }}</span>
              </template>
              <el-menu-item
                :index="itm.path"
                v-for="(itm, inx) in item.children"
                :key="inx"
                >{{ itm.meta.title }}</el-menu-item
              >
            </el-submenu>

            <el-menu-item
              :index="item.meta.menu != 1 ? item.path : item.children[0].path"
              v-if="
                item.children.length == 1"
              :key="index"
              style="color:black"
            >
              <!-- :index="item.children[0].path" -->
              <!-- <i class="marginRight svgI" :style="{color:color}">
              <svg-icon :data_iconName="item.meta.icon" :className="item.meta.icon+'_icon'" class="svgIcon"
            /></i> -->
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-col>
      <!-- <el-col :span="8" style="display:flex;align-items: center;justify-content: flex-end;padding-right: 5%;"> -->
        <!-- <el-input placeholder="请输入内容" v-model="inputSearch" class="inputSearch">
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input> -->
        <!-- <search id="header-search" class="right-menu-item" :style="$route.path=='/'?'':'color:white'"/> -->
        <!-- <div class="login"> -->
          <!-- <svg-icon className="login_icon" data_iconName="login" :style="$route.path=='/'?'':'color:white'"/> -->
        <!-- </div> -->
      <!-- </el-col> -->
    </el-row>
  </div>
</template>

<script>
// import Search from '@/components/HeaderSearch'
// import {jbrowse} from "@/api/dataList";

export default {
//   inject: ["reload"],
  name: "HelloWorld",
  data() {
    return {
      activeIndex: "1",
      name: this.$Storage.getItem("adm_name"),
      routerList: [],
      inputSearch:'',
      options:[]
    };
  },
  methods: {
    getMenuLinks() {
      var t = this.$router.options.routes.filter(function (val, index, arr) {
        // console.log(val,"val")
        // if(val.name!="detail"){
          return val.children;
        // }
      });
      this.routerList = t;
      // if(this.$route.path!="/"){
      //   console.log(this.routerList)
      //   //  var menuList=[]
      //   //   for(var i=0;i<this.$router.options.routes.length;i++){
      //   //     for(var a=0;a<this.$router.options.routes[i].children.length;a++){
      //   //       if(this.$router.options.routes[i].children[a].path==this.$route.path){
      //   //         menuList=this.$router.options.routes[i].children
      //   //       }
      //   //     }
          
      //   //   }
      // }
     
      // //console.log(this.$router,this.$route);
      
      
      // var list=this.$route.meta.menu
      //console.log(list)
      // var arr=this.$router.options.routes.filter(function (val) {
        //console.log(val)
        // return val
      // })
      /* if(list.length==2){
        var arr1=[]
        for(var i=0;i<arr.length;i++){
          if(i==0){
            arr1.push(arr[i])
          }else{
            for(var o=0;o<arr[i].children.length;o++){
              if(arr[i].children[o].children){
                arr1.push({alwaysShow:false,children:arr[i].children[o].children,component:arr[i].component,menu:arr[i].menu,meta:arr[i].children[o].meta,name:arr[i].children[o].name,path:arr[i].children[o].path,redirect:arr[i].children[o].redirect})
              }else{
                arr1.push({alwaysShow:arr[i].alwaysShow,children:[arr[i].children[o]],component:arr[i].component,menu:arr[i].menu,meta:arr[i].children[o].meta,name:arr[i].children[o].name,path:arr[i].children[o].path,redirect:arr[i].children[o].path})
              }
              
            }
          }
        }
         this.routerList = arr1; */
      // }else{
        //  this.routerList = arr;
      // }

      // console.log(arr)
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      if(this.$route.path==key){
        location.reload()
        this.$router.go(0)
      }
     
      //  this.reload()
    },
    refresh() {
      this.reload();
    },
    login() {
      this.$router.push({ path: "/login" });
    },
    logout() {
      this.$Storage.removeItem("adm_name");
      this.$Storage.removeItem("Admin-Token");
      this.name = this.$Storage.getItem("adm_name");
    },
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
    navLeftActive: function () {
      //console.log(this.$route);
      const { meta, path } = this.$route; 
      console.log(meta, path, this.$route, 111);
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      this.getMenuLinks();
      return path; //"/" + path.split("/")[1];
    },
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    this.getMenuLinks();
  },
  components:{
    // Search
  }
};
</script>

<style scoped>
.menu {
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 60px !important;
}
.svg{
  position: absolute;
    width: 120px !important;
    height: 53px !important;
}
.el-menu--horizontal {
  border: 0px !important;
  /* width: 80%; */
    /* margin-left: 28%; */
}
.login {
  margin-left: 15px;
  margin-top: 7px;
  cursor: pointer;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 2px solid #3b712f;
  color:#3b712f;
}
.el-menu--horizontal>.el-menu-item{
  height: 60px !important;
  line-height: 60px !important;
  font-size: 16px !important;/*no*/
  font-weight: bold;
  padding: 0 5px;
  /* margin-right: 60px; */
  /* color: white !important; */
}
.el-menu--horizontal>.el-menu-item:hover{
  color: black !important;
  /* color: #fff; */
  background-color: #6a8dad !important;
    text-decoration: none;
    background-color: #659a22;
    background-image: -moz-linear-gradient(top, #9ac92c, #659a22);
    background-image: -ms-linear-gradient(top, #9ac92c, #659a22);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#9ac92c), to(#659a22));
    background-image: -webkit-linear-gradient(top, #9ac92c, #659a22);
    background-image: -o-linear-gradient(top, #9ac92c, #659a22);
    background-image: linear-gradient(top, #9ac92c, #659a22);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ac92c', endColorstr='#659a22', GradientType=0);
    border-color: #51a351 #51a351 #387038;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
    text-shadow: 1px 1px #659a22;
}
/* .el-menu--horizontal .el-menu .el-menu-item:hover{
  color: black !important;
  background: rgba(8, 138, 105, 0.455) !important;
} */
.el-menu--horizontal .el-menu .el-menu-item{
  color: black !important;
  background-color: white !important;
  font-size: 13px !important;
  
  /* border-bottom: 2px dashed rgba(0, 128, 0, 0.719); */
}
.el-menu--horizontal>.el-submenu{
  /* margin-right: 60px !important; */
}
.el-menu--horizontal>.el-submenu:hover .el-submenu__title span{
  color: black !important;
}
.el-menu--horizontal .el-menu .el-menu-item:hover{
  color: rgb(252, 251, 251) !important;
  background-color: #6a8dad !important;
  transform:scale(1.1)
}

.inputSearch{
  width: 50%;
      margin-right: 20px;
    margin-left: -60px;
}
.pic{
  position: absolute;
    width: 140px;
    top: 5px;
    left: 10%

}
</style>
<style>
.el-menu--horizontal .el-submenu__title{
  /* color: white !important; */
  font-size: 16px !important;/*no*/
  font-weight: bold;
  padding: 0 5px;
}
.iblack .el-submenu__title i{
  color: black !important;
}
.iwhite .el-submenu__title i{
  color: white !important;
}
.el-menu--horizontal .el-submenu .el-submenu__title{
  height: 60px !important;
  line-height: 60px !important;
}
</style>
